/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong",
    blockquote: "blockquote",
    img: "img",
    em: "em",
    h3: "h3"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "bose-soundcontrol-vs-horizon-ix-hearing-aids-by-hearcom-which-ones-are-right-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bose-soundcontrol-vs-horizon-ix-hearing-aids-by-hearcom-which-ones-are-right-for-you",
    "aria-label": "bose soundcontrol vs horizon ix hearing aids by hearcom which ones are right for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bose SoundControl vs. Horizon IX hearing aids by hear.com: Which ones are right for you?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With hearing aids becoming more accessible and affordable this past year thanks to the over-the-counter (OTC) hearing aid bill signed by the president in 2021, you may be wondering if getting a hearing aid without a professional opinion is the right decision. Since there’s so many options, you’re probably a bit confused on which one to buy, but it all comes down to one thing: your individual hearing needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To help you make an informed decision, here are the 5 key differences between Bose SoundControl and Horizon IX hearing aids by hear.com:"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "brand-recognition--a-name-doesnt-always-say-it-all",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#brand-recognition--a-name-doesnt-always-say-it-all",
    "aria-label": "brand recognition  a name doesnt always say it all permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Brand recognition – a name doesn’t always say it all"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bose is a very recognizable brand name with quality sound products. But while they may be experts in headphones and stereos, they are very new to the hearing aid industry. Even though they released a hearing “amplifier” (which is not a hearing aid) years ago, they just started selling their first true hearing aid, the SoundControl, in 2021."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What’s the difference? A hearing amplifier just makes sounds louder and is not a hearing aid. It can damage your hearing if not used correctly, too. However, the SoundControl is a basic OTC hearing aid that comes pre-programmed for the most common, mild level of hearing loss, so it doesn’t take into consideration individual hearing needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com and their hearing aid experts have been in the hearing care field for decades and have helped over 100,000 people improve their hearing and quality of life. Partnering with well-known hearing aid engineers, top manufacturers and more than 2,000 hearing care specialists around the country, hear.com only produces and sells top-quality hearing aids that provide customized sound for everyone’s individual needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Why is customization important? Everyone is unique; we hear and prefer to hear sounds differently. A hearing aid that can be customized to your needs will enhance the world and sounds around you. One that is designed for your specific needs is crucial to happiness and overall quality of life."), "\n", React.createElement(LandingPageCta, {
    copy: "Get Horizon IX Hearing Aids Now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "horizon-ix-hearing-aids-offer-life-enhancing-features",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-ix-hearing-aids-offer-life-enhancing-features",
    "aria-label": "horizon ix hearing aids offer life enhancing features permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon IX hearing aids offer life-enhancing features"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Both the Horizon IX and SoundControl hearing aids sit behind the ear, but there is a big difference when it comes to design. The look and feel are very important to most hearing aid wearers. Hearing aid design has come a long way, and people no longer have to settle for big, bulky devices that everyone can see. They want devices that are nearly invisible and the option to match them to their hair or clothing. Here’s how Bose and hear.com differ:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Bose SoundControl devices are slightly larger and only come in the color gray."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon IX hearing aids"), " feature an award-winning, ultra-slim design and come in four different colors, including snow white and black & steel."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Beyond the design, both devices can be paired with their respective smartphone apps, giving wearers the ability to control volume and other settings discreetly from the palms of their hands. But the IX hearing aids offer many more features that, depending on your needs, are well worth the investment. Here are the favorites:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Rechargeability plus a mobile charging case with Qi technology"), " – Charging technology that lasts all day, and no more changing tiny batteries!"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Dynamic sound processing and acoustic motion sensors"), " – Provide the most natural sound and allow you to hear where sound is coming from, even when you’re walking"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Speech-enhancing algorithm"), " built in that provides crystal-clear sound"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Speech Focus and Relax Mode"), " – Two features not available with any other hearing aid today"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Tinnitus therapy"), " to help with ringing in ears"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Bluetooth connectivity"), " – Stream music, phone calls, tv and more directly into your ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "hearing aids with Bluetooth")), "\n"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "*It’s important to note that Bose SoundControl hearing aids are not rechargeable and cannot stream music or phone calls."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon.hearing.aid_ccexpress.jpeg",
    alt: "Horizon IX hearing aids by hear.com",
    className: "c-md-img"
  }), "\n", React.createElement(_components.em, null, "Horizon IX Hearing Aids by hear.com")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bose-hearing-aids-can-be-purchased-online-without-a-professional-consult",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bose-hearing-aids-can-be-purchased-online-without-a-professional-consult",
    "aria-label": "bose hearing aids can be purchased online without a professional consult permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bose hearing aids can be purchased online without a professional consult"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Non-customizable OTC hearing aids can now be purchased online without a professional consult, making better hearing more accessible to those who have had trouble accessing hearing care or devices. Because you don’t need a hearing test for Bose hearing aids, you can go online and order them to be delivered to your house without ever talking to a doctor."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While OTC hearing aids can be a solution for many people, a common misconception with hearing loss is that any hearing device will help you hear better. But in fact, hearing loss gets worse not only if it remains untreated, but also if you’re treating it incorrectly. If a hearing aid is just making everything around you louder and isn’t amplifying just the sounds you are specifically missing, it could be harming instead of helping."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Seeing a hearing care specialist or audiologist before you spend your money could save you a lot of frustration in the long run and ensure you’re investing in the right device for your hearing loss, and one that will last for the long term — not just for the moment."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "bose-soundcontrol-hearing-aids-less-expensive-less-features",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bose-soundcontrol-hearing-aids-less-expensive-less-features",
    "aria-label": "bose soundcontrol hearing aids less expensive less features permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bose SoundControl hearing aids: less expensive, less features"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you think you just have mild hearing loss and want to test out hearing aids for less than $1,000, Bose may be worth a shot. But keep in mind, they are less expensive for many reasons mentioned earlier in this article. They’re only appropriate for a more mild form of hearing loss, won’t be customized to your needs, aren’t rechargeable, don’t come equipped with Bluetooth technology, and only come in one color and design."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And unlike the Horizon IX, if your hearing loss worsens over time, your Bose hearing aids cannot be adjusted to adapt to that change since they are pre-programmed and not customizable. The old adage is true: You get what you pay for."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "horizon-ix-hearing-aids-work-for-all-levels-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-ix-hearing-aids-work-for-all-levels-of-hearing-loss",
    "aria-label": "horizon ix hearing aids work for all levels of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon IX hearing aids work for all levels of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you have mild, moderate or severe hearing loss, the Horizon IX hearing aids are equipped to solve for all three. You can adjust them for any environment and continue to hear clearly, even in difficult listening environments such as a busy restaurant or conference room. There’s even a special Speech Focus button that helps you focus in on and amp up conversations, not the background noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bose hearing aids are only suitable for a more mild level of hearing loss, not severe impairment. ", React.createElement("a", {
    href: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5024365/",
    target: "_blank"
  }, "According to the National Center for Biotechnology Information (NCBI)"), ", approximately 6.6 million Americans age 12 and up have ", React.createElement(_components.strong, null, "severe to profound hearing loss"), ", with the majority age 60 or older. So while Bose may work for some, many people will find they need a medical-grade hearing aid that takes into account more specific needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remember, hearing care IS healthcare. If you’re still unsure which device is right for you, ask yourself, do you trust your healthcare with anyone other than a specialist?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If the answer is no, then hear.com is the correct hearing care company for you. We will connect you to one of our licensed hearing care specialists. They will test your hearing and fit you for high-quality hearing aids like Horizon IX, which can be customized to your exact needs."), "\n", React.createElement(LandingPageCta, {
    copy: "Get Horizon IX Hearing Aids Now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
